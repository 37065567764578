.slider-item__description {
  padding: 0.2rem 0.5rem;
  text-align: center;
  margin: 0;
}

.slider-item__img-holder {
  margin: 0;
}

.slider-item__wrapper {
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}