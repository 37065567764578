.top-nav {
  background-color: rgb(40, 42, 53);
  overflow: hidden;
}

.top-nav__link {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 1.3rem 1.4rem;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 500;
}

.top-nav__link:hover {
  background-color: #ddd;
  color: black;
}

.top-nav__link-active {
  float: left;
  text-align: center;
  padding: 1.3rem 1.4rem;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 500;
  background-color: #ddd !important;
  color: black !important;
}

@media (min-width: 1200px) {
  .top-nav {
    padding-left: 20%;
  }
}