.main-section-grey {
  min-height: 830px;
  background-color: #dcdcdc;
  padding: 1rem 1rem 0.5rem 1rem;
}

.main-section-light-grey {
  min-height: 830px;
  background-color: #ededed;
  padding: 1rem 1rem 0.5rem 1rem;
}

.main-section-content {
  padding: 2rem 1rem;
  background-color: #f8f8f8;
  min-height: 750px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem;
}

@media (min-width: 1200px) {

  .main-section-grey {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 15%;
    padding-right: 15%;
  }

  .main-section-light-grey {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 15%;
    padding-right: 15%;
  }
}