.bot-footer {
  font-size: 1.2rem;
  background-color: rgb(40, 42, 53);
  text-align: center;
  padding: 2rem 0;
}

.bot-footer__cp-claim {
  color: #f2f2f2;
  text-align: center;
}

.bot-footer__cp-claim:hover {
  opacity: 0.8;
  cursor: pointer;
}